import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  text: {
    marginTop: 20,
    fontSize: 18
  }
});