export const sepia = {
  'body': {
    background: '#e8dcb8'
  },
  'span': {
    color: '#333 !important'
  },
  'p': {
    color: '#333 !important'
  },
  'li': {
    color: '#333 !important'
  },
  'h1': {
    color: '#333 !important'
  },
  'a': {
    'color': '#333 !important',
    'pointer-events': 'auto',
    'cursor': 'pointer'
  },
  '::selection': {
    background: 'lightskyblue'
  }
};